export const getUrlOrigin = () => {
    if (typeof window !== 'undefined') {
        console.log('getUrlOrigin window.location.origin', window.location.origin);
        return window.location.origin;
    }
    console.log('getUrlOrigin process.env.DOMAIN', process.env.DOMAIN);

    return process.env.DOMAIN;
}

export function replaceSlashes(input: string): string {
    return input.replace(/\/\/+/g, `/`)
}
